import { Instagram, LinkedIn, WebOutlined  } from "@mui/icons-material";
import WebIcon from '@mui/icons-material/Web';
import GitHubIcon from '@mui/icons-material/GitHub';

export default {
    name: 'VUTH. Sachakborameiy',
    latinName: 'Sachakborameiy VUTH.',
    title: 'Full-Stack Developer | Business Development | UX/UI Design | In House System',
    status: ' Single',
    nationality: ' Khmer',
    birthday: ' 07-Aug-1997',
    phone: ' +(855) 92 208 280',
    email: ' borameiy.vs@gmail.com',
    address: ' St. 05, Sangkat Chom Chao​ I, Khan Dangkor, Phnom Penh. ',
    language: [
        {
            title: 'Languages',
            lang: [
                'Khmer: Mother Tongue',
                'English: Good',
            ]
        }
    ], 

    socials: {
        facebook: {
            link: 'https://github.com/Sachakborameiy',
            text: 'My Facebook Profile',
            icon: <GitHubIcon />,
        },

        instagram: {
            link: 'https://www.instagram.com/borameiy/',
            text: 'My Instagram Profile',
            icon: <Instagram style={{color: "#FC5CED"}}/>,
        },
        linkedIn: {
            link: 'https://www.linkedin.com/in/vuth-sachakborameiy-371235195/',
            text: 'My Linkedin Profile',
            icon: <LinkedIn style={{color: "#0A66C2"}}/>,
        }
    },

    aboutMe: `A full-stack developer with 3+ years of experience in developing recruiting system, e-commerce platform, analytic dashboard, file transfer, web scraping, API integration, and 1 year of digital business development in developing conduct UAT, PVT testing of all assigned projects (Mobile + Internet Banking + Online Payment Integration).`,
    // aboutMe: `I dedicated and forward-thinking web developer with a keen interest in supporting the new generation of digital experiences. My journey in the world of web development has equipped me with a diverse set of skills and a genuine passion for creating innovative solutions.`,
    aboutMeSub: `I am proficient in front-end technologies such as ReactJs, SASS/CSS/Tailwind CSS/Material UI and JavaScript, with a strong focus based on business requirement and user-friendly design. My expertise extends to popular frameworks like React and Vue.js, enabling me to build dynamic and interactive web applications that resonate with modern users. On the back end, I am well-versed in server-side scripting languages like Node.js and Javascript, coupled with a solid understanding of databases such as MongoDB and MySQL. This comprehensive skill set allows me to architect robust and scalable web solutions.`,
    // aboutMe: `Actually, the term role model means any person whose do the same or emulated whose behavior. It has many kinds such as ideas, behavior or action of any person. Role model also has a strong effect on what a person decides to become in the future. Unfortunately, my role model is Bruce lee. I tried my best to study his martial art for prevent myself and I remembered the first thing he said that “knowing is not enough we must to apply. Willing is not enough we must do”. Although I have knowledge I should be try to do it. Even, it’s not so well, but it is my willing that I completed. One more thing that I like from him is “If you love life, don't waste time, for time is what life is made up of.” I really support this idea. I should be created a new purpose in my life. The last one of my role model in the future is Jack Ma. He is a Chinese business magnate, investor, and philanthropist. Nowadays, he is a co-founder and executive chairman of Alibaba Group. He tried to study English language until he know the internet working, so he can make team to create the technology like the business website. For me I really want to make my own business too. I would be know about my skill course to create a website. Specially, make a good relationship with my team and customer.`,
    
    workExperience: 'Work Experiences',
    education: 'Education',

    experiences: [
        {
            title: 'Senior Digital Business Dev - PPCBank',
            date: 'Feb/2023-Jan/2024',
            description: 'Senior Digital Business Development, and implemented/Enhancement everything from order management, Provide general technical supports to all staffs, branches and occasionally customers on Mobile App and Internet Banking, Product prototype of all various options, upgrade implementation of Digital Banking',
            subDescription: [
                "Conduct UAT, PVT testing of all assigned projects (Mobile + Internet Banking + Online Payment Integration)",
                "Coordinate and manage all of languages translations of all Mobile App and Internet Banking",
                "Analyze business process of competitor regarding digital server and prepare report for management in charge",
                "A focal management points between customers, branches an Information Technology Department until resolved when system bugs/defects are reported/found",
                "Provide general inquiries and technical supports to all staffs, branches and occasionally customers on Mobile App and Internet Banking",
                "Create Basic conceptual design for UX/UI",
                "Produce prototype of all vairous options",

            ],
        },
        {
            title: 'Senior Web Dev - Ministry of Commerce',
            date: 'Dec/2021-Aug/2022',
            description: 'Senior Web Developer, and implemented everything from order management, worked a part of a multi-disciplinary team, and tasks as requested by Leader Project.',
            subDescription: [
                "Execute unit and integration testing of the application",
                "Core technologies(HTML/SASS/JS)",
                "GraphQL, Typescript",
                "Familliarity with modern JS tooling & frameworks as React.js, Next.js",
                "Support report related to statistics",
                "Wrote proposal CMS(Content Management System)",
                "Helped end users to solve operating problems",
                "Design UX/UI in advanced adobe XD/Inkscape",
                "Worked on multiple projects simultaneously to a high standard",
                "Updates the existing client Websites",
                "Maintenance website application base on tasks management",

            ],
        },
        {
            title: 'Web Developer - Melo Restaurant',
            date: 'Apr/2020-Dec/2021',
            description: 'Web Developer, and implemented everything from order management, worked a part of a multi-disciplinary team, and tasks as requested by Leader Project.',
            subDescription: [
                "Wrote internal and external design specifications",
                "Documented software and technicla speicifications.",
                "E-commerce & Trading, Gamming website",
                "Stock trading dashboard",
                "Worked on multiple projects simultaneously to a high standard",
                "Escalated operational issues to senior management",
                "Worked on core Javascript, frontend and related languages via business requirements such as codeigniter, HTML/CSS/Booststrap Javascript, Laravel, mysql.",
                
            ],
        },
        {
            title: 'IT Training - MINAMI (CAMBODIA) CO.,LTD',
            date: 'Dec/2019-Mar/2020',
            description: 'Web Developer, and implemented everything from order management, worked a part of a multi-disciplinary team, and tasks as requested by Leader Project.',
            subDescription: [
                "Support IT productive (technical support)",
                "Front End (Web Development) + UX/UI Design",
                "PHP Training, Designing base on business requirement",
                "Learning Japanese languages",
                
            ],
        }
    ],   
    
    
    edu: [
        {
            school: 'Royal University of Phnom Penh',
            date: "2015-2019",
            title: "Certificated Bachelor Degree of Computer Science at Royal University of Phnom Penh. Computer Science - Web Development",
        },
        {
            school: 'National University of Management',
            date: "2015-2019",
            title: "Certificated Bachelor Degree of English of Education at National University of Management. English Teacher as Business.",
        },
        {
            school: "Koh Kong High School",
            date: "2012-2015",
            title: "Certificated Baccalaureate at Koh Kong High School, High School Students.",
        },
    ],

    training: "Other Training",

    ot: [
        {
            school: "Senior Digital Channel Development at Cambodia Asia Bank",
            date: "Aug/2022 - Nov/2022",
            title: [
                "Administrate on the current digital banking systems (IB/MB), middleware and any other digital related systems",
                "Develop and maintain the system integration between CAB bank and partners and/or other channels",
                "Work with vendor’s technical supports on issues rectification",
                "Manage and ensure the digital channel systems",
                "Develop and contribute API interface for system integration with new core banking (Mobile Banking, Internet banking, Western Union, Bankong, Fast Service, KHQR, etc.)",
                "Develop and manage on API interface integration for e-wallet system",
                "Strictly involve developing on new mobile and internet banking system as well as enhance on existing Internet/Mobile Banking application",
                // "Keep abreast and enhance of the latest technology for mobile/internet applications. ",
                "Facilitate in existing and ongoing projects with National Bank Cambodia.",
                "Execute related to Java Spring, C#, HTML, CSS, JQUERY, JAVASCRIPT Plus.",
                "Maintenance via APIs (SOAP, REST, XML)",
                "Proficient with functional and object-oriented programming",
                "Design UX/UI in advanced to Business Owner.",
            ]
        },
        {
            school: "IT Engineers Examination of IPA, Japan",
            date: "July-2019",
            title: ["Certificated IT Engineers Examination of IPA, Japan. IT Engineer",]
        },
        {
            school: "ELIC",
            date: "Feb-2017",
            title: ["Certificated of ELIC(PITCH PERFECT, Marketing Short Course. Marketing..",]
        }
    ],

    services: [
        {
            title: "Web Dev",
            description: "I have ...slfsdkfjslfksjdlfs",
            icon: <WebOutlined />,
        },
        {
            title: "Web Dev",
            description: "I have ...slfsdkfjslfksjdlfs",
            icon: <WebOutlined />,
        },
        {
            title: "Web Dev",
            description: "I have ...slfsdkfjslfksjdlfs",
            icon: <WebOutlined />,
        }
    ],

    skill: "Skills & Tools",
    skills: [
        {
            title: "Front-End",
            description: [
                "ReactJS",
                "Javascript",
                "Typescript",
                "Bootstrap",
                "Marterial UI",
            ],
        },
        {
            title: "Database",
            description: ["Ms SQL Server", "MySQL", "MongDB"],
        },
        {
            title: "Database",
            description: ["Ms SQL Server", "MySQL", "MongDB"],
        },
        {
            title: "Database",
            description: ["Ms SQL Server", "MySQL", "MongDB"],
        }
    ],

    knowledge: [
        {
            title: "Programming Languages",
            description: [
                "HTML, CSS, SASS, Javascript/Jquery, Typescript",
                "NodeJS",
                "PHP",
                "C++, Java Spring Boot"
            ]
        },
        {
            title: "Frameworks & Libraries",
            description: [ 
                "Bootstrap, Tailwind CSS, Material UI",
                "ReactJs, VueJs, NextJs",
                "ExpressJs, Apollo GraphQL",
                "Laravel, Codeigniter"
            ]
        },
        {
            title: "Databases",
            description: [
                "MongoDB",
                "MySQL",

            ]
        },
        {
            title: "Tools",
            description: [
                "Git, GitHub, Bitbucket",
                "Visual Studio Code",
                "IntelliJ IDEA",
                "Jira",
                "Postman",
                "Google Workspace"
            ]
        },
        {
            title: "Platforms",
            description: [
                "Docker",
                "WSO2 Service",
                "Tomcat Service",
            ]
        },
        {
            title: "Software Development Life Cycle",
            description: [
                "Agile Mindset",
                "Agile Methodologies",
            ]
        },
        // {
        //     title: "Web Development",
        //     description: [
        //         "Knowledge of NextJs, ReactJs, PHP, HTLML, CSS, SASS, XML, Material UI, Bootstrap, Codeigniter, Laravel, Ajax, JSON, Javascript/Jquery, MySQL, MongooseDB, GraphQL.",
        //         "Awareness of the Web Development industry, and new technologies.",
        //         "Social Media Monetisation Strategies.",
        //         "Writing up technical manuals and user instructions",
        //         "Strategically thinking through all parts of the design process",
        //         "Knowledge of payment processing, customs clearance and multi-currency pricing.",
        //         "Knowledge of international web standards and protocols",
        //         "Knowledge in advanced of computer application such as Boot Window, Setup Program, Research Format, Ms. PowerPoint, Word & Excel, Internet Search Engine.",
                
        //     ],
        // },
    ],

    hobbies: [
        {
            title: "Hobbies",
            description: [
                "Self-Motivated and Optimistic.",
                "High Creative of System up to date.",
                "Prove Experience of application security.",
                "Quick learning, and effective communication.",
                "Good interpersonal and communication skills.",
                "Experience working with a development team.",
                "Willing to work smart under pressure and flexibility work. ",
                "Ability to multitask skills with a good problem-solving attitude.",
            ]
        },
        {
            title: "Interests",
            description: [
                "Travelling: Mountain, Seaside",
                "Musician: Play Piano, Guitar..",
                "Researching: Reading Book and Magazine, Self-Development",
                "Health Understanding: Exercise, Running Sport Event, Travelling, Baseball..",
            ]
        }
    ],

    projects: [
        {
            tag: "CMS",
            image: '/01_preview.__large_preview.avif',
            title: "Project1",
            caption: "A short description ",
            description: "This is desc1 A short description A short description",
            links: [
                {
                    link: "https//www.google.com", icon: <WebIcon />
                },
                {
                    link: "https//www.google.com", icon: <GitHubIcon />
                },
            ]
        },
        {
            tag: "Store",
            image: "https://imgv3.fotor.com/images/share/Free-pink-gradient-aesthetic-background-from-Fotor.jpg",
            title: "Project1",
            caption: "A short description",
            description: "This is desc1 A short description A short description",
            links: [
                {
                    link: "https//www.google.com", icon: <WebIcon />
                },
                {
                    link: "https//www.google.com", icon: <GitHubIcon />
                },
                {
                    link: "https//www.google.com", icon: <WebIcon />
                },
            ]
        },
        {
            tag: "Web Portal",
            image: "https://imgv3.fotor.com/images/share/Free-pink-gradient-aesthetic-background-from-Fotor.jpg",
            title: "Project1",
            caption: "A short description",
            description: "This is desc1 A short description A short description",
            links: [
                {
                    link: "https//www.google.com", icon: <WebIcon />
                },
                {
                    link: "https//www.google.com", icon: <GitHubIcon />
                },
                {
                    link: "https//www.google.com", icon: <WebIcon />
                },
            ]
        },
    ]

}